// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: ("woff2","woff"),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Anton",
    $fileName: "anton-v11-latin",
    $weights: "400",
    $types: ("woff2","woff")
);

@include fontFaceV2(
    $fontName: "Spartan",
    $fileName: "spartanmb",
     $weights: ("300", "600"),
    $types: ("woff2","woff")
);