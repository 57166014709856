
// GLOBAL
//////////////////////////////

div#pageWrap {
    overflow: hidden;
}

.contacting {
	border-left: rem(2px) solid $color1; padding: rem(20px) 0 rem(20px) rem(25px);

	> span {
		display: block; font-size: rem(13px); line-height: rem(29px);

		@include breakpoint(full) {
			font-size: rem(14px); line-height: rem(30px);
		}

		a {
			text-decoration: none; color: $primary;

			&:hover {
				color: $color2;
			}
		}
	}
}

.opening {
	border-left: rem(2px) solid $color1; padding: rem(20px) 0 rem(20px) rem(25px);

	@include breakpoint(large) {
		padding: rem(17px) 0 rem(17px) rem(25px);
	}

	@include breakpoint(giant) {
		padding: rem(20px) 0 rem(20px) rem(25px);
	}

	> span {
		display: block; font-size: rem(13px); line-height: rem(29px);

		@include breakpoint(full) {
			font-size: rem(14px); line-height: rem(30px);
		}

		&.head {
			font-weight: 600; text-transform: uppercase; color: $alert; margin-bottom: rem(5px);
		}
	}
}

.brand {
	padding: rem(100px) 0 0;

	@include breakpoint(large) {
		padding: 0; position: absolute; top: rem(20px); left: rem(18px); z-index: 101;
	}

	@include breakpoint(giant) {
		top: rem(23px);
	}

	@include breakpoint(full) {
		top: rem(40px); left: 50%; margin-left: rem(-744px);
	}

	.branding {
		max-width: rem(200px); width: 100%; margin: 0 auto; display: block;

		@include breakpoint(small) {
			max-width: rem(260px);
		}

		@include breakpoint(medium) {
			max-width: rem(280px);
		}

		@include breakpoint(large) {
			max-width: rem(174px);
		}

		@include breakpoint(giant) {
			max-width: rem(240px);
		}

		@include breakpoint(full) {
			max-width: rem(310px);
		}

		img {
			width: 100%;
			margin-bottom: 0;
		}
	}
}



// HEADER
//////////////////////////////

header {
	.headerSub {
		@include breakpoint(large) {
			padding-top: rem(100px);
		}

		@include breakpoint(giant) {
			padding-top: rem(120px);
		}

		@include breakpoint(full) {
			padding-top: rem(170px);
		}
	}
	
	.headerMain {
		position: relative;
		padding-top: rem(50px);
		

		@include breakpoint(large) {
			padding-top: rem(120px);
		}

		@include breakpoint(giant) {
			padding-top: rem(140px);
		}

		@include breakpoint(full) {
			padding-top: rem(170px);
		}

		&:before {
			content: ''; background:$secondary; width: 100%; height: calc(100% - 100px); position: absolute; bottom: 0; left: 0; z-index: 0;

			@include breakpoint(small) {
				height: calc(100% - 125px); 
			}

			@include breakpoint(medium) {
				height: calc(100% - 150px); 
			}

			@include breakpoint(large) {
				height: calc(100% - 266px); 
			}

			@include breakpoint(giant) {
				height: calc(100% - 311px); 
			}

			@include breakpoint(full) {
				height: calc(100% - 470px); 
			}
		}

		.row {
			z-index: 1;
		}

		.contacting, .opening {
			display: none;

			@include breakpoint(large) {
				display: block;
			}
		}

		.contacting {
			@include breakpoint(giant) {
				margin-top: rem(25px);
			}

			@include breakpoint(full) {
				margin-top: rem(75px); margin-bottom: rem(25px);
			}
		}

		.opening {
			@include breakpoint(full) {
				margin-bottom: rem(25px);
			}
		}

		.btn {
			display: none;

			@include breakpoint(large) {
				display: inline-block; margin-bottom: rem(50px);
			}

			@include breakpoint(full) {
				margin-bottom: rem(100px);
			}
		}

		.headerPadding {
		    @include breakpoint(medium, max) {
		    	padding: 0;		    
		    }
		}

		.swiper-slide {
			.row, .col {
				height: 100%;
			}

			.col {
				@include breakpoint(medium) {
					margin-left: 54.166%;
				}

				@include breakpoint(large) {
					margin-left: 50%;
				}

				@include breakpoint(full) {
					margin-left: 55.6%;
				}
			}
		}

		.overlay {
			background:rgba(black,.5); height: 100%; width: 60%; margin: 0 auto; text-align: center;
			position: absolute;
    		right: 0;

			@include breakpoint(small) {
				width: 50%; margin-left: 35%;
			}

			@include breakpoint(medium) {
				width: 40%; margin-left: 0;
			}

			@include breakpoint(large) {
				width: 47.7%;
			}

			@include breakpoint(giant) {
				width: 47.9%;
			}

			@include breakpoint(huge) {
				width: 48.1%;
			}

			@include breakpoint(full) {
				width: 42.88%;
			}

			.claim {
				display: table; margin: 0 auto; padding-top: rem(71px); font-size: rem(15px); line-height: rem(25px); text-transform: uppercase; letter-spacing: .2em; color: $light; text-align: right;

				@include breakpoint(small) {
					padding-top: rem(91px);font-size: rem(17px); line-height: rem(27px);
				}

				@include breakpoint(medium) {
					padding-top: rem(116px);
				}

				@include breakpoint(large) {
					padding-top: rem(148px); font-size: rem(18px); line-height: rem(28px);
				}

				@include breakpoint(giant) {
					padding-top: rem(173px);
				}

				@include breakpoint(full) {
					padding-top: rem(249px); font-size: rem(20px); line-height: rem(30px);
				}

				b {
					display: block; font-family: $displayFont; font-size: rem(25px); line-height: rem(35px); text-transform: none; letter-spacing: 0;
					font-weight: 500;

					@include breakpoint(small) {
						 font-size: rem(35px); line-height: rem(45px);
					}

					@include breakpoint(large) {
						 font-size: rem(40px); line-height: rem(50px);
					}

					@include breakpoint(giant) {
						 font-size: rem(45px); line-height: rem(55px);
					}

					@include breakpoint(full) {
						 font-size: rem(50px); line-height: rem(60px);
					}
				}
			}
		}

		.headerSwiper {
			.swiper-wrapper {
				.swiper-slide {
					> div {
						position: relative; display: block; height: rem(200px); width: 100%;

						@include breakpoint(small) {
							height: rem(250px);
						}

						@include breakpoint(medium) {
							height: rem(300px);
						}

						@include breakpoint(large) {
							height: rem(375px);
						}

						@include breakpoint(giant) {
							height: rem(425px);
						}

						@include breakpoint(full) {
							height: rem(600px)
						}

						&.slider01 {
							&.lazyLoaded {
							    @include responsiveImage('layout/slider-01.jpg', (tiny, medium), true); background-size:cover;
							}
						}

						&.slider02 {
							&.lazyLoaded {
							    @include responsiveImage('layout/slider-02.jpg', (tiny, medium), true); background-size:cover;
							}
						}

						&.slider03 {
							&.lazyLoaded {
							    @include responsiveImage('layout/slider-03.jpg', (tiny, medium), true); background-size:cover;
							}
						}

						&.slider04 {
							&.lazyLoaded {
							    @include responsiveImage('layout/slider-04.jpg', (tiny, medium), true); background-size:cover;
							}
						}
					}
				}
			}
		}

		.headerPartner {
			position: relative; padding-top: rem(25px); padding-bottom: rem(25px);

			@include breakpoint(small) {
				padding-top: rem(35px); padding-bottom: rem(35px);
			}

			@include breakpoint(medium) {
				display: flex; justify-content: flex-start; align-items: center;
			}

		    .swiper-container.logoSwiper {
		    	padding-bottom: 2rem;
				
				@include breakpoint(tiny) {
					.swiper-wrapper {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.swiper-slide {
							width: 30%;
						}
					}
				}
		    }

		    .swiper-pagination {
				bottom: 0rem;
				@include breakpoint(tiny) {
					display: none;
				}
			}

		    .flexContainer {
		    	display: block;


		    }

		    .flex,
		    .flex2 {
		        display: flex; justify-content: flex-start; align-items: center; flex-wrap:nowrap; overflow-y:hidden;

		        @include breakpoint(giant) {
		            overflow-y:visible; justify-content: flex-start; align-items: center; flex-wrap: nowrap; padding-bottom: 0;
		        }

		        .par1 {
		        	margin-right: rem(36px); flex-basis: rem(150px); max-width: rem(150px); min-width: rem(150px);

		        	@include breakpoint(full) {
		        		margin-right: rem(50px);
		        	}

		        	img {
		        		background: none !important;
		        	}
		        }

		        .par2 {
		        	margin-right: rem(36px); flex-basis: rem(190px); max-width: rem(190px); min-width: rem(190px);

		        	@include breakpoint(full) {
		        		margin-right: rem(50px);
		        	}
		        }

		        .par3 {
		        	flex-basis: rem(160px); max-width: rem(160px); min-width: rem(160px);
		        }

		        > a {
		            img {
		            	width: auto;
		            }
		        }
		    }

		    .flex2 {
		    	margin-top: rem(25px);
		    }
		}

		.yellow {
			background:$color1; padding: rem(20px) rem(25px); text-align: right;

			@include breakpoint(small) {
				padding: rem(40px) rem(25px);
			}

			@include breakpoint(full) {
				padding: rem(60px) rem(30px);
			}

			span {
				display: block; font-family: $displayFont; font-size: rem(25px); line-height: rem(35px); color: $color2;

				@include breakpoint(small) {
					font-size: rem(30px); line-height: rem(40px);
				}

				@include breakpoint(medium) {
					font-size: rem(35px); line-height: rem(45px);
				}

				@include breakpoint(giant) {
					font-size: rem(40px); line-height: rem(50px);
				}

				@include breakpoint(full) {
					font-size: rem(48px); line-height: rem(60px);
				}
			}
		}
	}
}



// MAIN CONTENT
//////////////////////////////

main {
	.content, &.content {
		padding: rem(50px) 0 rem(70px);

		@include breakpoint(small) {
			padding: rem(55px) 0 rem(105px);
		}

		@include breakpoint(large) {
			padding: rem(100px) 0 rem(120px);
		}

		@include breakpoint(full) {
			padding: rem(150px) 0 rem(150px);
		}
	}

	.spaceTop {
		padding-top: rem(10px);

		@include breakpoint(small) {
			padding-top: rem(25px);
		}

		@include breakpoint(large) {
			padding-top: rem(50px);
		}

		@include breakpoint(full) {
			padding-top: rem(75px);
		}
	}

	.boxFlex {
		background:$secondary; padding: rem(25px) rem(25px) rem(1px); margin-bottom: rem(25px);

		@include breakpoint(small) {
			padding: rem(35px) rem(35px) rem(10px);display: flex; justify-content: flex-start; align-items: center;
		}

		@include breakpoint(full) {
			padding: rem(45px) rem(45px) rem(20px);
		}

		img {
			width: auto; margin-bottom: rem(10px);

			@include breakpoint(small) {
				margin-bottom: rem(25px);
			}
		}

		div {
			@include breakpoint(small) {
				padding-right: rem(36px);
			}
		}
	}

	.boxContent {
		background:$secondary; padding: rem(20px) rem(25px) rem(1px); margin-bottom: rem(25px);

		@include breakpoint(small) {
			padding: rem(30px) rem(35px) rem(10px);
		}

		@include breakpoint(full) {
			padding: rem(40px) rem(45px) rem(20px);
		}

		.btn {
			margin-bottom: rem(25px);
		}
	}

	.contentPartner {
		border:$baseBorder; padding: rem(15px); height: 100%; display: flex; justify-content: center; align-items: center;

		@include breakpoint(full) {
			padding: rem(25px);
		}

		.cmsBackend & {
			display: block;
		}
	}

	.maBottom {
		margin-bottom: rem(25px);
	}

	.modalImage {
		margin-bottom: rem(25px);
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

.teaser {
    position: relative; 

	&:after {
		content: ''; background:$secondary; width: 100%; height: calc(100% - 75px); position: absolute; bottom: 0; left: 0; z-index: 0;

		@include breakpoint(small) {
			height: calc(100% - 100px);
		}

		@include breakpoint(full) {
			height: calc(100% - 150px);
		}
	}

	.row {
		z-index: 1;
	}

	&.teaserFlex {	
		.swiper-pagination.swiper-pagination-bullets {
			bottom: -3rem;
			@include breakpoint(large) {
				display: none;
			}
		}

		.swiper-wrapper {
		    @include breakpoint(large) {
			    display: flex;
			    justify-content: space-between;	

			    .swiper-slide {
		    	    width: 32%;
			    }	    
		    }
		}
	}

    .flex {
        display: flex; justify-content: flex-start; flex-wrap:nowrap; overflow-y:hidden;

        @include breakpoint(large) {
            overflow-y:visible; justify-content: center; align-items: flex-start; flex-wrap: wrap; padding-bottom: 0; width: calc(100% + 36px); margin-left: rem(-18px);
        }

        > a {
            flex-basis: 100%; max-width: 100%; min-width: 100%; margin-right: rem(36px);

            @include breakpoint(small) {
                flex-basis: 65%; max-width: 65%; min-width: 65%;
            }

            @include breakpoint(medium) {
                flex-basis: 45%; max-width: 45%; min-width: 45%;
            }

            @include breakpoint(large) {
                flex-basis: calc(33.333% - 36px); max-width: calc(33.333% - 36px); min-width: calc(33.333% - 36px);
            }

            &:last-of-type {
            	margin-right: 0;
            }
        }
    }

    a {
    	text-decoration: none; text-align: right;

	    .teaser01, .teaser02, .teaser03, .teaser04 {
	    	display: block; width: 100%; height: rem(150px); position: relative;

	    	@include breakpoint(small) {
	    		height: rem(200px);
	    	}

			@include breakpoint(full) {
	    		height: rem(300px);
	    	}

	    	&:before {
	    		content: ''; background:rgba(black,.5); width: 25%; height: 100%; position: absolute;  top: 0; right: 0; transition: width 300ms ease-in-out;
	    	}
	    }

	    .teaser01 {
    		background: image-url('layout/slider-03.jpg') no-repeat center; background-size: cover;
	    	&.lazyLoaded {
	    	}
	    }

	    .teaser02 {
    		background: image-url('layout/slider-01.jpg') no-repeat center; background-size: cover;
	    	&.lazyLoaded {
	    	}
	    }

	    .teaser03 {
    		background: image-url('layout/slider-02.jpg') no-repeat center; background-size: cover;
	    	&.lazyLoaded {
	    	}
	    }

	    .teaser04 {
	    	&.lazyLoaded {
	    		background: image-url('layout/tiny-teaser-04.jpg') no-repeat center; background-size: cover;

		    	@include breakpoint(tiny) {
		    		background: image-url('layout/teaser-04.jpg') no-repeat center; background-size: cover;
		    	}
		    }
	    }

	    .text {
	    	display: block; font-family: $displayFont; font-size: rem(25px); line-height: rem(35px); color: $dark; position: relative; padding: rem(20px) rem(25px) rem(20px) 0; transition: color 300ms ease-in-out;

	    	@include breakpoint(small) {
	    		font-size: rem(35px); line-height: rem(45px); padding: rem(40px) rem(25px) rem(40px) 0;
	    	}

	    	@include breakpoint(full) {
	    		font-size: rem(50px); line-height: rem(60px);
	    	}

	    	&:before {
	    		content: ''; background:$color1; width: 25%; height: 100%; position: absolute;  top: 0; right: 0; z-index: -1; transition: background 300ms ease-in-out;
	    	}
	    }

	    &:hover {
	    	.teaser01, .teaser02, .teaser03, .teaser04 {
		    	&:before {
		    		width: 100%;
		    	}
		    }
	    }
    }

    .yellow {
    	background:$color1; margin-top: rem(25px);

    	@include breakpoint(medium) {
    		margin-top: rem(100px); height: calc(100% - 100px);
    	}

    	@include breakpoint(full) {
    		margin-top: rem(150px); height: calc(100% - 150px);
    	}

    	.head {
    		display: block; font-family: $displayFont; font-size: rem(25px); line-height: rem(35px); color: $color2; position: relative; padding: rem(20px) 0 rem(10px) rem(25px);

    		@include breakpoint(small) {
    			font-size: rem(35px); line-height: rem(45px); padding: rem(40px) rem(25px) rem(10px) rem(25px);
    		}

    		@include breakpoint(full) {
    			font-size: rem(50px); line-height: rem(60px); padding: rem(40px) rem(25px) rem(40px) rem(25px);
    		}
    	}

    	.text {
    		display: block; font-size: rem(13px); line-height: rem(29px); padding: 0 rem(25px) rem(20px);

    		@include breakpoint(small) {
    			padding: 0 rem(25px) rem(40px);
    		}

    		@include breakpoint(giant) {
    			padding: 0 rem(75px) rem(40px); font-size: rem(14px); line-height: rem(30px);
    		}

    		@include breakpoint(huge) {
    			padding: 0 rem(100px) rem(40px);
    		}

    		@include breakpoint(full) {
    			padding: 0 rem(125px) rem(40px);
    		}
    	}
    }
} 



// FOOTER
//////////////////////////////

footer {
	.footerMain	{
		position: relative; 

		&:after {
			content: ''; background:$secondary; width: 100%; height: calc(100% - 75px); position: absolute; bottom: 0; left: 0; z-index: 0;

			@include breakpoint(small) {
				height: calc(100% - 100px); 
			}

			@include breakpoint(full) {
				height: calc(100% - 150px); 
			}
		}

		.row {
			z-index: 1;
		}

		.flex {
			@include breakpoint(small) {
				display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; padding: rem(25px) 0 rem(50px);
			}

			@include breakpoint(medium) {
				padding: 0;
			}

			@include breakpoint(large) {
				padding-top: 9.14286rem;
				padding-bottom: 2rem;
			}

			@include breakpoint(pregiant) {
				padding-top: rem(150px);
    			// align-items: center;
			}

			@include breakpoint(full) {
				padding-top: rem(222px);
			}
		}
		
		.contacting {
			margin-top: rem(25px);

			@include breakpoint(small) {
				margin: 0 rem(45px) 0 0;
			}

			@include breakpoint(large) {
				padding: rem(23px) 0 rem(24px) 1.78571rem;
				height: 126px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			@include breakpoint(giant) {
				height: 135px;
			}

			@include breakpoint(full) {
				margin-right: rem(100px);
			}
		}

		.opening {
			margin: rem(-25px) 0 rem(25px);

			@include breakpoint(small) {
				margin: 0;
			}

			@include breakpoint(giant) {
				margin: 0 rem(45px) 0 0;
			}

			@include breakpoint(full) {
				margin-right: rem(100px);
			}
		}

		.footerLink {
	    	display: block; text-decoration: none; text-align: right;

		    .footerImg {
		    	display: block; width: 100%; height: rem(150px); position: relative; 

		    	@include breakpoint(small) {
		    		height: rem(200px);
		    	}

		    	@include breakpoint(full) {
		    		height: rem(300px);
		    	}

		    	&.lazyLoaded {
		    		background: image-url('layout/tiny-footer.jpg') no-repeat center; background-size: cover;

			    	@include breakpoint(tiny) {
			    		background: image-url('layout/footer.jpg') no-repeat center; background-size: cover;
			    	}
			    }

		    	&:before {
		    		content: ''; background:rgba(black,.5); width: 25%; height: 100%; position: absolute;  top: 0; right: 0; 
		    	}
		    }

		    .text {
		    	display: block; position: relative; padding: rem(20px) rem(25px) rem(20px) 0;

		    	@include breakpoint(small) {
		    		padding: rem(40px) rem(25px) rem(40px) 0;
		    	}

		    	@include breakpoint(large) {
		    		padding: rem(44px) rem(25px) rem(44px) 0;
		    	}

		    	&:before {
		    		content: ''; background:$color1; width: 25%; height: 100%; position: absolute;  top: 0; right: 0; z-index: -1;
		    	}
		    }
	    }
	}

	.footerBottom {
		@include breakpoint(large) {
			padding: rem(10px) 0;
		}

		@include breakpoint(full) {
			padding: rem(20px) 0;
		}
	}
} 



// MISC
//////////////////////////////

.swiper {
	position: relative;

	.swiper-button-prev, .swiper-button-next {
		position: absolute; width: rem(20px); height: rem(20px); margin-top: 0; z-index: 10; cursor: pointer; background: none; transition: opacity 300ms ease-in-out;

		@include breakpoint(full) {
			width: rem(25px); height: rem(25px);
		}

		&:before {
			@extend .icon; color: $light; position: absolute; top: 0; left: 0; font-size: rem(22px); line-height: rem(22px);

			@include breakpoint(full) {
				font-size: rem(25px); line-height: rem(25px);
			}
		}

		&:hover {
			opacity: .75;
		}
	}

	.swiper-button-prev {
		top: rem(20px); right: 31%; left: auto; 

		@include breakpoint(tiny) {
			top: rem(25px); right: 30%;
		}

		@include breakpoint(small) {
			top: rem(30px); right: 26%;
		}

		@include breakpoint(medium) {
			top: rem(65px); right: 19%;
		}

		@include breakpoint(large) {
			top: rem(85px); right: 22.5%;
		}

		@include breakpoint(giant) {
			top: rem(100px);
		}

		@include breakpoint(full) {
			top: rem(145px);
			right: 20.5%;
		}

		&:before {
			@extend .icon-angle-left;
		}
	}

	.swiper-button-next {
		top: rem(20px); right: 22%; left: auto; 

		@include breakpoint(tiny) {
			top: rem(25px); right: 24%
		}

		@include breakpoint(small) {
			top: rem(30px); right: 21%;
		}

		@include breakpoint(medium) {
			top: rem(40px); right: 19%;
		}

		@include breakpoint(large) {
			top: rem(60px); right: 22.5%;
		}

		@include breakpoint(giant) {
			top: rem(70px);
		}

		@include breakpoint(full) {
			top: rem(110px);
			right: 20.5%;
		}

		&:before {
			@extend .icon-angle-right;
		}
	}
}

.cmsBackend {
	.noBackend,
	span.footerImg.lazyBg,
	a.footerLink {
		display: none;
	}
}