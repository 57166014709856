/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 14px;

$baseLineHeight: 30px;

$h1Size: 50px;

$h2Size: 40px;

$h3Size: 30px;

$h4Size: 20px;

$h5Size: 20px;

$h6Size: 20px;

$mainFont: "Spartan", sans-serif;

$displayFont: "Anton", sans-serif;

$iconFont: "icomoon";

// Project colors

$primary: #555;
$secondary: #f2f2f2;
$light: #fff;
$medium: #808080;
$dark: #333;
$border: #aaa;
$alert: #0e71b2;
$color1: #feed00;
$color2: #3e65a1;

$baseGap: 18px;

$rowMaxWidth: 1520px;

$columnCount: 12;

$baseBorder: 2px solid $border;

$outline: 1px dotted $border;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	exclamation: "!", 
	check-box: "\e958", 
	clock: "\e968", 
	envelope: "\e9b0", 
	mobile-swipe: "\e9e1", 
	heart: "\ea00", 
	lens: "\ea16", 
	angle-down: "\ea1d", 
	angle-left: "\ea1e", 
	angle-right: "\ea1f", 
	angle-up: "\ea21", 
	play: "\ea29", 
	map-marker: "\ea41", 
	mobile: "\ea4b", 
	paper-plane: "\ea6d", 
	pencil: "\ea7a", 
	phone: "\ea81", 
	basket: "\eace", 
	star: "\eaf0", 
	plus: "\f067", 
	minus: "\f068", 
	check: "\f00c", 
	close: "\f00d", 
	pdf: "\f1c1", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		center: (justify-content:center),
		cols: (9, 11),
		first: (order:-1),
		suffix: (2),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (5, 7),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6, 8, 10, 12),
		prefix: (4),
		suffix: (1),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (4, 5, 6, 12),
		last: (order:1),
		suffix: (1),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (3, 4, 5, 6, 7, 9, 10, 11, 12),
		prefix: (0, 4),
		suffix: (1),
	),
	pregiant: (
		width: em(1062px, $bpContext), 
		cols: (3, 5, 6, 7),
		suffix: (1),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 5, 6, 7),
		suffix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (4, 5, 8),
		suffix: (2),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (2, 3, 4, 5, 8),
		suffix: (1, 3),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (0, auto),
	),
);

