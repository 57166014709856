* {
	margin: 0; padding: 0;

	&, &:before, &:after {
		box-sizing: inherit;
	}
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $primary;
	font-family: $mainFont;
	font-size: 100% * ($baseFontSize / 16px);
	hyphens: manual;
	line-height: rem($baseLineHeight);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($baseLineHeight); opacity:1!important;

	&:not(.javascript) {
		* {
			transition: none!important; 
		}
	}
}

iframe, [data-iframe] {
	border: none; width: 100%;

	&[data-src] {
		@extend %lazyloading;
	}
}

div[data-iframe]:not(.enabled) {
	padding:rem($baseGap); max-width: rem(400px); background:$secondary; margin: 0 auto; width: calc(100% - 30px);

	.privacyButton {
		margin: 0 0 rem(5px);
	}

	p {
		font-size: rem(12px); line-height: rem(17px); text-align: center; max-width: 100%;
	}
}


// HEADLINES
//////////////////////////////

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	display: block;
	text-rendering: optimizeLegibility;
}

h1, .h1 {
	font-family: $displayFont; font-size: rem(25px); line-height: rem(35px); color: $dark; margin-bottom: rem(20px); border-left: rem(2px) solid $color1; padding: rem(20px) 0 rem(20px) rem(25px);

	span {
		display: block; font-family: $mainFont; font-size: rem(14px); line-height: rem(24px); color: $primary; font-weight: 300; text-transform: uppercase; letter-spacing: .2em; margin-top: rem(5px);
	}

	@include breakpoint(small) {
		font-size: rem(35px); line-height: rem(45px);

		span {
			font-size: rem(16px); line-height: rem(26px);
		}
	}

	@include breakpoint(large) {
		font-size: rem(40px); line-height: rem(50px); margin-bottom: rem(30px);

		span {
			font-size: rem(17px); line-height: rem(27px);
		}
	}

	@include breakpoint(full) {
		font-size: rem(50px); line-height: rem(60px); 

		span {
			font-size: rem(20px); line-height: rem(30px);
		}
	}
}

h2, .h2 {
	font-family: $mainFont; font-size: rem(16px); line-height: rem(26px); color: $dark; margin-bottom: rem(20px); border-left: rem(2px) solid $color1; padding: rem(20px) 0 rem(20px) rem(25px);
	font-weight: 600;

	@include breakpoint(small) {
		font-size: rem(18px); line-height: rem(28px);
	}

	@include breakpoint(large) {
		font-size: rem(22px); line-height: rem(32px); margin-bottom: rem(30px);
	}

	@include breakpoint(full) {
		font-size: rem(30px); line-height: rem(40px); 
	}
}

h3, .h3 {
	font-family: $mainFont; font-size: rem(15px); line-height: rem(25px); color: $dark; margin-bottom: rem(20px); border-left: rem(2px) solid $color1; padding: rem(20px) 0 rem(20px) rem(25px);

	@include breakpoint(small) {
		font-size: rem(16px); line-height: rem(26px);
	}

	@include breakpoint(large) {
		font-size: rem(20px); line-height: rem(30px); margin-bottom: rem(30px);
	}

	@include breakpoint(full) {
		font-size: rem(25px); line-height: rem(35px);
	}
}

h4, .h4 {
	@extend .marginBottom; font-size: rem(18px); line-height: rem(28px); font-weight: 700;

    @include breakpoint(giant) {
        font-size: rem(22px); line-height: rem(32px);     
    }
}

h5, .h5 {
	@extend .marginBottom; font-size: rem(17px); line-height: rem(27px); font-weight: 700;

    @include breakpoint(giant) {
        font-size: rem(20px); line-height: rem(30px);     
    }
}

h6, .h6 {
	@extend .marginBottom; font-size: rem(16px); line-height: rem(26px); font-weight: 700;

    @include breakpoint(giant) {
        font-size: rem(18px); line-height: rem(28px);     
    }
}


// TEXT
//////////////////////////////

.content p, .content address, .content ul, .content ol, .content dl, .content table, .marginBottom {
    margin-bottom: rem(25px);
}

.content p, .content address, .content ul, .content ol, .content table td, .content dt, .content dd, form.default label, form.default input, form.default textarea, form.default select {
    font-size: rem(13px); line-height: rem(29px);

    @include breakpoint(giant) {
      font-size: rem($baseFontSize); line-height: rem($baseLineHeight);
    }
}

strong, b {
    font-weight: 600;
}

address {
    font-style: normal;
}

.nowrap {
    white-space:nowrap;
}

.upper {
    text-transform:uppercase;
}

.taCenter {
    text-align: center;
}

.taRight {
    text-align: right;
}

#privacyPolicy {
	a {
		display: inline-block; word-break: break-all;
	}
}


// LINKS
//////////////////////////////

a {
	color: $color2; word-wrap: break-word; transition: color 300ms ease-in-out;

	&:focus, &:hover, &:active {
		color: $primary; text-decoration: none;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit; text-decoration: none; color: $primary;
	}
}

a:not(.btn):focus, [tabindex]:focus {
	outline: $outline; outline-offset: rem(5px);
}

hr, .hr {
	height: rem(1px); width: rem(100px); border: none; border-bottom: $baseBorder; clear: both; margin:0 auto rem(25px); padding-bottom:rem(10px); display: block;

    @include breakpoint(small) {
        margin-bottom:rem(35px); padding-bottom:rem(20px);
    }

    @include breakpoint(large) {
        margin-bottom:rem(50px); padding-bottom:rem(30px);
    }
    
    &.clear { 
         height:0; width:0; background:none; border: none; margin:0; padding: 0;
    }
}


// LISTS
//////////////////////////////

ul, ol {
  list-style: none;

  .content & {
        @extend .marginBottom;

        li {
            padding:0 0 rem(5px) rem(20px); position: relative;

            &:before {
                @extend .icon; @extend .icon-angle-right; color: $primary; position: absolute; top: rem(8px); left: rem(0px); 
            }
        }

        ul {
            margin-left: 1rem;
            margin-bottom: 0;
        }
    }
}

.content dl {
	@include pie-clearfix;

	dt, dd {
        display: block; background:$secondary;
    }

    dt {
        padding: rem(15px) rem(20px) 0; font-weight: 600;

        @include breakpoint(large) {
            padding: rem(15px) rem(25px) 0;
        }
    }

    dd {
        padding:0 rem(20px) rem(15px); margin-bottom: rem(10px);

        @include breakpoint(large) {
            padding:0 rem(25px) rem(15px); margin-bottom: rem(20px);
        }
    }
}

.content ol {
	list-style-type: none; counter-reset: item;

	li {
		padding-top: 0.3rem; padding-bottom: 0.3rem; display: table; counter-increment: item;

		&:before {
			content: counters(item, ".") ". "; display: table-cell; padding-right: 0.3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {
			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}


// IMAGES
//////////////////////////////

img {
	vertical-align: bottom; float: none; height: auto; max-width: 100%; width: 100%;

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none; width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
		background: none !important;
	}

	.cmsPage & {
		margin-bottom: 1.8rem;
	}
}


// TABLE
//////////////////////////////

.content table {
	display: table; border-collapse: collapse; width: 100%; max-width: 100%; text-align: left; font-size: rem($baseFontSize);

	@include breakpoint(small, max) {
		overflow-x: auto; display: block;
	}
	caption {
		text-align: left; padding: rem($baseGap) rem($baseGap) rem(25px); display: table-caption; font-weight: 700; font-size: 1.2rem;
	}

	thead {
		border-collapse: collapse; display: table-header-group;
	}

	tbody {
		width: 100%; overflow-x: scroll; display: table-row-group;

	tr {
		padding: rem($baseGap) 0;

		&:not(:last-child) {
			border-bottom: rem(1px) solid $medium;
			}
		}
	}

	tr {
		display: table-row;
	}

	th, td {
		padding: rem($baseGap); display: table-cell; vertical-align: top;
	}

	@include breakpoint(small, max) {
		td {
			min-width: 50vw;
		}
	}
}
