// SETTINGS

$break: 	large; 	// break from mobile to desktop navigation
$barHeight: 50px; 	// height for quickbar
$barHide: 	false; 	// if bar hides on scroll mobile (true, false)
$navHover:	true;	// hover functionality for desktop navigation
$time: 		400ms; 	// transition for some stuff


// MOBILE NAVIGATION

@include breakpoint($break, max) {
	#navigation {
		transition:transform $time; background:$secondary; border: rem(30px) solid $secondary;  border-top: rem($barHeight) solid $secondary; width: 100%; overflow:hidden; position:fixed; right:0; z-index:1002; bottom:0; top:28px;

		.naviOut {
			overflow: hidden; overflow-y: auto; height:100vh;
		}

		.naviContact {
			padding: rem(30px) 0 rem(30px); text-align: center; 

			.contacting {
				padding: 0; border: none;
			}

			.opening {
				padding: 0 0 rem(15px); border: none;

				> span {
					&.head {
						margin: rem(15px) 0 rem(5px);
					}
				}
			}
		}

		ul {
			background:$secondary; position: relative; padding-bottom: rem(150px); width:100%;

			li {
				display: flex; width: 100%;

				&.terminvereinbarung {
					font-weight: 600;
				}

				a, span {
					align-items: center; border-top: rem(1px) solid $border; color:$primary; display: flex; font-size:rem(13px); line-height: rem(29px); text-transform: uppercase; justify-content: space-between; padding:rem(10px) rem(15px); text-decoration: none; width: 100%; transition:background $time, color $time;

					&.subToggler {
						&:after {
							font-family: $iconFont; content: map-get($iconMap, angle-right);
						}
					}
				}

				&.navBack {
					span {
						justify-content: flex-start; text-transform: uppercase; font-size: rem(12px); letter-spacing: .2em; background:darken($secondary,5%);

						&:before {
							font-family: $iconFont; content: map-get($iconMap, angle-right); transform:rotate(180deg) translateY(rem(1px)); margin-right: rem(7px);
						}
					}
				}

				.sub {
					&:before {
						display: none;
					}
				}

				&.active > a, &.active > span, a.active, span.active {
					color:$alert; font-weight: 600;
				}
			}

			&.sub {
				position:absolute; left:0; top:0; transform:translateX(100%); height:100vh; transition:transform $time;
			}

			&.current {
				transform:translateX(0%);
			}
		}
	}

	html.hiddenScroll {
		overflow:hidden;
	}
}

@include breakpoint(small) {
	#navigation {
		border-left: rem(175px) solid $secondary; border-right: rem(175px) solid $secondary;
	}
}


// DESKTOP NAVIGATION

@include breakpoint($break) {
	#navigation {
		z-index: 100; background:none; position: absolute; top: rem(18px); left: 0; right: 0; border: none;

		.scroll & {
			position: fixed; background:$secondary; top: 0; box-shadow: 0 0 rem(20px) 0 rgba(black,0.2);
			animation:fadeDown 1200ms ease-in-out;

			@keyframes fadeDown {
			    from {
			        top:-200px; opacity:0;
			    }

			    to {
			        top:0; opacity:1;
			    }
			}

			.naviOut {
				display: flex; justify-content: space-between; align-items: center;
			}

			.naviContact {
				display: block;
			}
		}

		.naviOut {
			max-width: rem($rowMaxWidth); margin: 0 auto; padding: 0 rem(18px); 
		}

		.naviContact {
			display: none;

			.contacting {
				.write {
					display: none;
				}
			}

			.opening, .socials {
				display: none;
			}
		}

		nav {
			.navi {
				display: flex; justify-content: flex-end;

				li {
					padding: rem(15px) 0; display: flex; align-items: center; position: relative; margin-left: rem(20px);

					&.startseite, &.jobs, &.sponsoring, &.anfahrt, &.impressum, &.datenschutzerklaerung {
						display: none;
					}

					a, span {
						padding:rem(5px) 0; font-size:rem(12px); line-height: rem(23px); white-space: nowrap; color:$primary; display: flex; align-items: center; text-decoration: none; cursor: pointer; position: relative; width: 100%; text-transform: uppercase;

						&:before {
							position: absolute; bottom: rem(0px); width: 0; height: rem(2px); background:$alert; content:''; left: 0; transition: width $time;
						}

						&.subToggler {
							&:after {
								font-family: $iconFont; content: map-get($iconMap, angle-down); margin-left: rem(3px);
							};
						}
					}

					&.active > a, &.active > span, &:hover > a, &:hover > span,  > a.active, > span.active {	
						color: $alert;

						&:before {
							 width: 100%; 
						}
					}

					&.active > a, &.active > span, > a.active, > span.active {	
						font-weight: 600;
					}

					&.navBack {
						text-align: center;

						span {
							display: none; 
						}

						&:before {
							cursor: pointer; width: 100%; font-family: $iconFont; content: map-get($iconMap, times); display: block; font-size: rem(13px); line-height: rem(23px); background:$secondary; transition:background $time; padding:rem(10px) rem(20px);
						}

						&:hover {
							&:before {
								background:darken($secondary,10%);
							}
						}
					}			

					.sub {
						background:$secondary; position: absolute; top:120%; left:0; min-width: 100%; opacity: 0; visibility: hidden; pointer-events: none; transition:opacity $time, visibility $time, top $time;

						li {
							padding: 0; margin: 0;

							a, span {
								transition:background $time; padding:rem(10px) rem(20px); border-top: rem(1px) solid $border; font-size:rem(13px);

								&:before {
									display: none;
								}

								&.subToggler {
									&:after {
										content: map-get($iconMap, angle-right); 
									};
								}
							}


							&.active > a, &.active > span, a.active, span.active, &:hover > a, &:hover > span, a:hover, span:hover {
								background:darken($secondary,3%); color:$alert;
							}

							@if $navHover {
						 		body.isDesktop & {
									&:hover {
										> .sub {
											top:0%; opacity: 1; visibility: visible; pointer-events: auto;
										}
									}

									.navBack {
										display: none;
									}
								}
							};
						}

						&.current {
							top:100%; opacity: 1; visibility: visible; pointer-events: auto;
						}

						&.open {
							top:100%;
						}

						.sub {
							top:20%; left:100%;

							&.current, &.open {
								top:0;
							}
						}
					}

					@if $navHover {
				 		body.isDesktop & {
							&:hover {
								> .sub {
									top:100%; opacity: 1; visibility: visible; pointer-events: auto;
								}
							}

							.navBack {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

@include breakpoint(giant) {
	#navigation {
		top: 28px;

		.naviContact {
			.contacting {				
				.write {
					display: inline-block;
				}
			}
		}

		nav {
			.navi {
				li {
					margin-left: rem(20px);

					&.startseite{
						display: flex;
					}
				}
			}
		}
	}
}

@include breakpoint(huge) {
	#navigation {
		nav {
			.navi {
				li {

					a, span {
						font-size:rem(14px); line-height: rem(24px); 
					}
				}
			}
		}
	}
}

@include breakpoint(full) {
	#navigation {
		top: rem(55px);

		nav {
			.navi {
				li {
					margin-left: rem(30px);
				}
			}
		}
	}
}


// DESKTOP NAVIGATION ADD

.naviAdd {
	display: none;

	@include breakpoint($break) {
		display: flex; justify-content: flex-end;

		li {
			padding: rem(15px) 0; display: flex; align-items: center; position: relative; margin-left: rem(20px);

			@include breakpoint(full) {
				margin-left: rem(30px);
			}

			&.startseite {
				display: none;
			}

			a, span {
				padding:rem(5px) 0; font-size:rem(13px); line-height: rem(23px); white-space: nowrap; color:$primary; display: flex; align-items: center; text-decoration: none; cursor: pointer; position: relative; width: 100%; text-transform: uppercase;

				@include breakpoint(giant) {
					font-size:rem(14px); line-height: rem(24px); 
				}

				&:before {
					position: absolute; bottom: rem(0px); width: 0; height: rem(2px); background:$alert; content:''; left: 0; transition: width $time;
				}
			}

			&.active > a, &.active > span, &:hover > a, &:hover > span,  > a.active, > span.active {	
				color: $alert;

				&:before {
					 width: 100%; 
				}
			}

			&.active > a, &.active > span, > a.active, > span.active {	
				font-weight: 600;
			}
		}
	}
}


// MOBILE QUICKBAR

@include breakpoint($break, max) {
	#quickbar {
		display:flex; justify-content:center; align-items:center; width: 100%; height:rem($barHeight); background:$color1; position:fixed; top: 0; left:0; right: 0; margin: auto; z-index:1003; padding:0 rem(15px); transition:transform $time;

		label[for="naviToggled"] {
			display:block; cursor:pointer; width:rem(20px);	height:rem(20px); position:relative; overflow:hidden;

			span {
				transition:0.4s linear; text-indent:-9999px; width:0; top:50%; transform:translate(-50%, -50%);

				&:before, &:after {
					top:50%; width:rem(20px); transition:top $time ease-in-out, transform $time 0.2s linear;
				}

				&:before {
					transform:translate(-50%, -50%) rotate(45deg);
				};

				&:after {
					transform:translate(-50%, -50%) rotate(-45deg)
				};
			}

			span, span:before, span:after {
				content:""; position:absolute; display:block; background:$dark; height:2px; left:50%; transform-origin:center
			};
		};
	};
	
	#naviToggled {
		&:not(:checked) {
	 		& ~ * #navigation, & ~ #navigation {
				transform:translateX(100%);
	 		}

	 		& ~ #quickbar {				
				label[for="naviToggled"] {
					span {
						width:20px;

						&:before, &:after {
							transform:translate(-50%, -50%); transition:top $time 0.2s ease-in-out, transform $time linear;
						};

						&:before {
							top:calc(50% - 5px);
						};

						&:after {
							top:calc(50% + 5px);
						};
					}

					&:after {
						opacity:0; visibility:hidden
					}
				}
	 		} 		
	 	}
	}
}

#naviToggled {
	display:none;
}

@include breakpoint($break) {
	#quickbar {
		display: none;
	}
}