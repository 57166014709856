.vanilla-datepicker {
    background:$primary;
    border:none;
    color:$light;
    font-size: 14px;
    position: absolute;
    width:rem(250px);
    z-index:100;
}
.vanilla-datepicker > .title-nav {
    align-items:center;
    background:$light;
    border:solid rem(1px) $primary;
    display:flex;
    flex-direction:row;
    font-weight:bold;
    justify-content:space-between;
    margin:rem(2px);
    width:auto;
    color:$primary;
}
.vanilla-datepicker > .title-nav > .month-navigate,
.vanilla-datepicker > .title-nav > .year-navigate {
    align-items:center;
    cursor:pointer;
    display:flex;
    flex:0 15px;
    font-weight:bold;
    height:15px;
    justify-content:center;
    margin:1px;
    padding:2px;
    text-align: center;
}
.vanilla-datepicker > .title-nav > .month-navigate:first-child,
.vanilla-datepicker > .title-nav > .year-navigate:first-child {
}
.vanilla-datepicker > .title-nav > .month-navigate:hover,
.vanilla-datepicker > .title-nav > .year-navigate:hover {
    background:$secondary;
}
.vanilla-datepicker > .week-header {
    font-weight: 400;
}
.vanilla-datepicker > .week-header,
.vanilla-datepicker > .week {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}
.vanilla-datepicker > .week-header > div {
    align-items:flex-start;
    display:flex;
    flex:1;
    justify-content:center;
}
.vanilla-datepicker > .week > div {
    align-items:flex-end;
    border:solid 1px rgba($light, .35);
    cursor:pointer;
    display:flex;
    flex:1;
    justify-content:center;
    margin:1px;
    padding:2px;
}
.vanilla-datepicker > .week > div.no-select {
    cursor:auto;
    background:lighten($primary, 30%);
    color:$light;
}
.vanilla-datepicker > .week > div.selected {
    background-color:darken($primary, 15%);
    font-weight:bold;
}
.vanilla-datepicker > .week > div:not(.no-select):hover {
    background:darken($primary, 15%);
}