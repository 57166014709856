
.btn {
    @extend %animatedTransform;
    background: $color1; border: none; color: $dark; cursor: pointer; display: inline-block;font-size: rem(13px); line-height: rem(23px); padding: rem(10px) rem(18px) rem(10px) rem(15px); text-align: center; text-decoration: none; font-family: $mainFont; text-transform: uppercase; font-weight: 600;

    @include breakpoint(full) {
      font-size: rem($baseFontSize); line-height: rem($baseLineHeight);
    }

    span {
      display: none;

      @include breakpoint(huge) {
        display: inline-block;
      }
    }

    &:hover, &:active, &:focus {
        background: $dark; color: $light;
    }

    .content & {
      margin-bottom: rem(25px);
    }
}

.noMargin {
  margin-bottom: 0 !important;
}

.clear {
  clear: both;
  float: none;
}

.noPadding {
  padding: 0;
  @include breakpoint(small) {
    margin-left: 1.5rem;
  }
}

.overflowVisible {
  @include breakpoint(large, max) {
    overflow: visible;  
  }
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

.maBo25 {
  margin-bottom: rem(25px);
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 42.777%;
    // padding-bottom: 42.17%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

.notificationArea {
  position: fixed;
  bottom: rem(40px);
  left: rem(40px);
  z-index: 999999;
  visibility: hidden;
  
  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:last-child) {
    margin-bottom: $baseGap;
  }

  .notification {
    position: relative;
    transition: 300ms all ease-in-out;
    overflow: hidden;
    padding: 1em;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    max-height:9999999px;
    visibility: visible;

    @include breakpoint(large) {
      font-size:rem(16px);
      line-height: rem(22px);
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: #333;
      
      &, .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert
    }
    &.success, &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      opacity: 0;
      visibility: hidden;
      padding-top: 0;
      padding-bottom: 0;
      margin-left:-100px;
    }
  }
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

.popUpContainer { 
    body:not(.cmsBackend) & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: opacity .3s, visibility .3s;
        visibility: hidden;
        z-index: 1000000;
 
        .popUpContent {
            width: 100%;
            background: transparent;
            max-height: 90vh;
            max-width: calc(100% - #{rem(110px)}); // for the close label
            overflow: hidden;
            overflow-y: auto;
     
            @include breakpoint(giant) {
                max-width: 80%;
            }
     
            @include breakpoint(full) {
                max-width: 70%;
            }
        }
    }
 
    label {
        display:none;
 
        body:not(.cmsBackend) & {
            background: rgba(black, 0.8);
            display: flex;
            height: 100%;
            justify-content: flex-end;
            padding: rem($baseGap);
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: -1;
     
            i {
                cursor: pointer;
                display: block;
                height: rem(35px);
                width: rem(35px);
                position: relative;
                transition: background .3s;
     
                &:hover {
                    background: rgba(black, 0.8);
     
                    &:before, &:after {
                        background: white;
                    };
                }
     
                &:before, &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: rem(30px);
                    height: rem(2px);
                    background: #ddd;
                }
     
                &:before {
                    transform: translate(-50%,-50%) rotate(45deg);
                }
     
                &:after {
                    transform: translate(-50%,-50%) rotate(-45deg);
                }
            }
        }
    }
}
 
input[id^="popUpToggled"] {
    display: none;
 
    &:checked {
 
        + {
 
            .popUpContainer {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                position: fixed;
            }
        }
    }
}